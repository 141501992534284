import React, { useCallback, useMemo } from 'react';
import {
  CustomSelectWrapper,
  OptionsCustom,
  OptionsCustomWrapper,
  SelectField,
} from 'Components/CustomSelect/style';
import { ILocationProps } from 'Components/NewProject/ModalNewPeoject';
import { ILocation } from 'Redux/projects/type';
import { InputFieldClear } from './style';

interface ICustomSearchProps<T> {
  value: ILocationProps;
  setValue: (s: ILocationProps) => void;
  data: T[];
  placeholder: string;
  onSelect: (s: ILocationProps) => void;
  children?: React.ReactNode;
  border?: boolean;
  onActive: (s: boolean) => void;
  active: boolean;
}

export const CustomSearch = ({
  data,
  placeholder,
  onSelect,
  children,
  border,
  value,
  setValue,
  onActive,
  active,
}: ICustomSearchProps<ILocation>) => {
  const handleClickOnOption = useCallback(
    ({ id, name }: ILocationProps) => {
      onSelect({ id, name });

      onActive(false);
    },
    [placeholder],
  );

  const options = useMemo(
    () =>
      data
        .filter(
          (element) =>
            element.name.toLowerCase().includes(value.name.toLowerCase()) &&
            element.name.toLowerCase() !== value.name.toLowerCase(),
        )
        .map((element, index) => (
          <OptionsCustom
            key={index}
            onClick={() =>
              handleClickOnOption({ id: element.id, name: element.name })
            }
          >
            {element.name}
          </OptionsCustom>
        )),
    [data, value],
  );

  const handleActive = () => {
    onActive(!active);
  };

  const handleActiveOnKeyPress = () => {
    onActive(true);
  };

  return (
    <CustomSelectWrapper
      onKeyPress={handleActiveOnKeyPress}
      onClick={handleActive}
    >
      <SelectField border={border}>
        <InputFieldClear
          error={!value.id}
          placeholder={placeholder}
          value={value.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue({ id: value.id, name: e.target.value });
          }}
        />
        {children}
      </SelectField>
      {<OptionsCustomWrapper active={active}>{options}</OptionsCustomWrapper>}
    </CustomSelectWrapper>
  );
};
