import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Text } from 'Components/Text/index';
import { ButtonSubmit } from 'Components/FormAuth/style';
import { assignUser } from 'Redux/projects/thunk';
import { useQuery } from 'hooks/useQuery';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { AssignUserContainer, ButtonWrapper } from './style';

export const AssignUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const projectIdStr = useQuery('projectId');
  const email = useQuery('email');
  const needRegister = useQuery('needRegister');
  const { isAuth } = useTypedSelector((state) => state.user);
  const projectId = Number(projectIdStr);

  const handleClick = () => {
    if (!email || !projectId) return;
    console.log(needRegister);
    if (!needRegister) {
      history.push('/register');
      return;
    }
    if (!isAuth) {
      history.push('/login');
      return;
    }

    dispatch(
      assignUser({
        projectId: projectId,
        emailUser: email,
        history: (s) => history.push(s),
      }),
    );
  };

  return (
    <AssignUserContainer>
      <ButtonWrapper>
        <ButtonSubmit onClick={handleClick}>
          <Text style={{ fontSize: '14px', textTransform: 'uppercase' }}>
            assign to project
          </Text>
        </ButtonSubmit>
      </ButtonWrapper>
    </AssignUserContainer>
  );
};
