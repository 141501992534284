import {
  ICreateNewStepPostProps,
  IFormLogin,
  IFormRegister,
  IPostEditProjectProps,
  IPostProjectsProps,
  IResetPassword,
  ISearchFormInBack,
} from './type';

export const URL = process.env.REACT_APP_BASE_URL_DEV;

export const registerFetch = (value: IFormRegister) => {
  return fetch(`${URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...value, admin: true }),
  });
};

export const loginFetch = (value: IFormLogin) => {
  return fetch(`${URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(value),
  });
};

export const forgotPassword = (email: string) => {
  return fetch(`${URL}/auth/forgot_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  });
};

export const resetPassword = (value: IResetPassword) => {
  return fetch(
    `${URL}/auth/reset_pass?email=${value.email}&token=${value.token}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('accessToken') as string) as string
        }`,
      },
      body: JSON.stringify({
        password: value.password,
        confirmPassword: value.confirmPassword,
      }),
    },
  );
};

export const getUser = () => {
  return fetch(`${URL}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
  });
};

export const getProjects = () => {
  return fetch(`${URL}/projects`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
  });
};

export const postProject = ({ value, userId }: IPostProjectsProps) => {
  return fetch(`${URL}/projects?userId=${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify(value),
  });
};

export const sendEmailToUser = (email: string, projectId: number) => {
  return fetch(
    `${URL}/projects/send_email_to_user?projectId=${projectId}&email=${email}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('accessToken') as string) as string
        }`,
      },
    },
  );
};

export const deleteParticipantFromProject = (
  projectId: number,
  userEmail: string,
) => {
  return fetch(`${URL}/projects/project_user?projectId=${projectId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify({ email: userEmail }),
  });
};

export const assignUserPut = (email: string, projectId: number) => {
  return fetch(
    `${URL}/projects/assign_user?projectId=${projectId}&email=${email}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('accessToken') as string) as string
        }`,
      },
    },
  );
};

export const getLastProject = () => {
  return fetch(`${URL}/projects/lasts`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
  });
};

export const updateProject = (value: IPostEditProjectProps) => {
  return fetch(`${URL}/projects/update?projectId=${value.project}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify(value.data),
  });
};

export const getLocations = () => {
  return fetch(`${URL}/projects/locations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
  });
};

export const getProjectPdf = (projectId: number) => {
  return fetch(`${URL}/files/pdf?projectId=${projectId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
      responseType: 'blob',
    },
  });
};

export const markPieceReady = (
  projectId: number,
  id: number,
  isDone: boolean,
) => {
  return fetch(`${URL}/projects/mark_piece?projectId=${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify({ isDone: isDone, pieceId: id }),
  });
};

export const updateStatus = (
  projectId: number,
  status: 'develop' | 'complete',
) => {
  return fetch(`${URL}/projects/update_status?projectId=${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify({ status: status }),
  });
};

export const verifyAccountPost = (email: string, token: string) => {
  return fetch(`${URL}/auth/verify_account?token=${token}&email=${email}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateEditing = ({
  projectId,
  isEditingId,
  userId,
  isEditing,
}: {
  projectId: number;
  isEditingId: number;
  userId: number;
  isEditing: boolean;
}) => {
  return fetch(`${URL}/projects/update_is_editing?projectId=${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify({
      userId: userId,
      isEditing: isEditing,
      isEditingId: isEditingId,
    }),
  });
};

export const refreshTokenPost = (refreshToken: string) => {
  return fetch(`${URL}/auth/refresh_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken: refreshToken }),
  });
};

export const createNewStepPost = ({
  projectId,
  position,
}: ICreateNewStepPostProps) => {
  return fetch(`${URL}/projects/create_content?projectId=${projectId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: JSON.stringify({ position: position }),
  });
};

export const getForm = ({ projectId, pieceId }: ISearchFormInBack) => {
  return fetch(
    `${URL}/projects/content?projectId=${projectId}&pieceId=${pieceId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('accessToken') as string) as string
        }`,
      },
    },
  );
};

export const deleteStepDelete = ({ projectId, pieceId }: ISearchFormInBack) => {
  return fetch(
    `${URL}/projects/delete_content?projectId=${projectId}&pieceId=${pieceId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('accessToken') as string) as string
        }`,
      },
    },
  );
};

export const postUserAvatar = (formData: FormData) => {
  return fetch(`${URL}/users/upload_avatar`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
    body: formData,
  });
};

export const getAllMessages = (projectId: number) => {
  return fetch(`${URL}/chat/messages?projectId=${projectId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('accessToken') as string) as string
      }`,
    },
  });
};
