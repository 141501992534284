import { useEffect, useState } from 'react';
import openSocket from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { IIsEditing } from 'Pages/form-builder/formShcema';
import { editFormsLocal, saveFormAfterEditing } from 'Redux/form/thunk';
import {
  IMeMessages,
  IMessages,
  IMessagesMissed,
  IMessagesUser,
  MessagesState,
} from './type';
import { useHistory } from 'react-router';
import { toggleLoading } from '../../Redux/loading/thunk';

const SERVER_URL = `${process.env.REACT_APP_BASE_URL_DEV}/chat`;

export const useChat = () => {
  const [messages, setMessage] = useState<MessagesState>({});
  const [messagesMissed, setMessageMissed] = useState<IMessagesMissed[]>([]);
  const [chatId, setChatId] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useTypedSelector((state) => state.user.id);
  const { id: formId } = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );
  const projectId = useTypedSelector(
    (state) => state.projects.currentProjects?.id,
  );

  const socket = openSocket(`${SERVER_URL}`, {
    transports: ['websocket', 'polling'],
    forceNew: true,
    reconnectionAttempts: Infinity,
    timeout: 10000,
  });

  useEffect(() => {
    if (!projectId) return;

    socket.emit('joinRoom', {
      projectId: projectId,
      pieceId: formId || 0,
      token: JSON.parse(
        localStorage.getItem('accessToken') as string,
      ) as string,
    });

    socket.on('joinedRoom', (data: IMessages) => {
      setChatId(data.chatId);
      setMessage({ [data.chatId]: data.messages.messages });
      setMessageMissed((prevState) => {
        const findIndex = prevState.findIndex((x) => x.chatId === data.chatId);
        if (findIndex > -1) {
          prevState[findIndex].messageStatus = 0;
        }
        return prevState;
      });
    });

    socket.on('msgToClient', (data: IMeMessages) => {
      setMessage((prevMsg) => {
        return {
          ...prevMsg,
          [data.chatId]: [...data.messages.messages],
        };
      });

      setMessageMissed((prevState) => {
        const findIndex = prevState.findIndex((x) => x.chatId === chatId);
        if (findIndex > -1) {
          prevState[findIndex] = data.messages.readMessages[0];
        }
        return prevState;
      });
    });

    socket.on('editEventToClient', (data: { editData: IIsEditing }) => {
      dispatch(
        editFormsLocal({
          user: data.editData.user,
          isEditing: data.editData.edit,
          formId: formId,
        }),
      );
      dispatch(toggleLoading({ loading: false }));
      if (!data.editData.edit) {
        setTimeout(
          () =>
            dispatch(
              saveFormAfterEditing({
                pieceId: formId,
                projectId: projectId,
                history: (s) => history.push(s),
              }),
            ),
          2000,
        );
      }
    });

    return () => {
      socket.emit('leaveRoom', {
        projectId: projectId,
        pieceId: formId || 0,
        token: JSON.parse(
          localStorage.getItem('accessToken') as string,
        ) as string,
      });

      setMessage({});
      socket.disconnect();
    };
  }, [projectId, formId, userId]);

  const sendMessage = (message: string) => {
    socket.emit('msgToServer', {
      projectId: projectId,
      pieceId: formId || 0,
      token: JSON.parse(
        localStorage.getItem('accessToken') as string,
      ) as string,
      message: message,
      time: new Date(),
    });
  };

  const editChange = (isEditing: boolean, isEditingId: number) => {
    dispatch(toggleLoading({ loading: true }));
    socket.emit('editEvent', {
      userId: userId,
      projectId: projectId,
      token: JSON.parse(
        localStorage.getItem('accessToken') as string,
      ) as string,
      isEditing: isEditing,
      isEditingId: isEditingId,
    });
  };

  const msg: IMessagesUser[] = messages[chatId];

  return { msg, sendMessage, messagesMissed, setMessageMissed, editChange };
};
